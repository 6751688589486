import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Role () {
    return(
        <div>
            <Navbar/>
            <div>
            <div className="cont-role-banner">
                <p className="vis-tit">Role Model</p>
                <p className="vis-para">Profiles of great personalities who can influence  and make difference.</p>
            </div>
            
            <div>
                <p className="fourofour">404</p>
            </div>

            </div>
            <Footer />
        </div>
    )
}

export default Role