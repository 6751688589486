import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Learning () {
    return(
        <div>
            <Navbar/>
            <div>
            <div className="cont-learn-banner">
                <p className="vis-tit">Learning</p>
                <p className="vis-para">Quest for enhancing skills and continuous self inprovement.</p>
            </div>
            <div className="cont-one-education">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">Mastering Time Management for Better Learning Outcomes.</p>
                                <a className="edu-para-one" href="#">Time management is a critical skill for successful learning. With proper planning and organization, you can balance academic demands with personal and professional responsibilities. Here’s how:</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a> 
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./learblogone">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./learblogone.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">The Power of Peer Learning: Why Collaboration Matters.</p>
                                <a className="edu-para-one" href="#">Learning doesn’t have to be a solitary endeavor. Peer learning, where students collaborate and learn from one another, is a powerful approach that fosters deeper understanding and skill development.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./learblogtwo">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./learblogtwo.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">The Science Behind Effective Learning Techniques.</p>
                                <a className="edu-para-one" href="#">Learning is a fundamental skill, yet many of us don’t fully understand how to learn effectively. Research in cognitive psychology offers valuable insights into strategies that enhance learning.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./learblogthree">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./learblogthree.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">Learning Beyond the Classroom: The Rise of Online Education.</p>
                                <a className="edu-para-one" href="#">The digital revolution has transformed how we learn. Online education has made knowledge accessible to anyone with an internet connection, breaking down barriers of geography and affordability.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="./learblogfour">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./learblogfour.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" />        
                    </div>

                    <div className="col-md-4">
                        Hi
                    </div>
                </div>
                <br/>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Learning